// Do not edit this file! It is generated by prebuild script

import { StaticImage } from "gatsby-plugin-image"
import { IStaticImageProps } from "gatsby-plugin-image/dist/src/components/static-image.server"
import React from "react"

import { AboutContent } from "../content-types"

const logoProps: Omit<IStaticImageProps, "src" | "alt"> = {
  placeholder: "none",
  layout: "fluid",
  maxWidth: 1088,
  sizes:
    "(max-width: 400px) 85px, (max-width: 800px) 170px, (max-width: 1200px) 255px, (max-width: 1205px) 256px, (max-width: 1280px) 272px, (max-width: 1600px) 340px, (max-width: 2000px) 425px, (max-width: 2353px) 500px, (max-width: 2400px) 510px, (max-width: 2409px) 512px, (max-width: 2560px) 544px, (max-width: 2800px) 595px, (max-width: 3200px) 680px, (max-width: 3600px) 765px, (max-width: 4000px) 850px, (max-width: 4400px) 935px, (max-width: 4706px) 1000px, (max-width: 4819px) 1024px, (max-width: 5120px) 1088px",
  transformOptions: {},
  blurredOptions: {},
}
export const aboutContent: AboutContent = {
  pageTitle: "About Us",
  wtDescription: {
    main: (
      <>
        <p>
          Wisdom Treasury Consulting is an IT and business solution provider
          company specialized in consulting services in the area of SAP supply
          chain and global trade systems with focus on implementation and
          process re-engineering. We cover the entire implementation cycle:
        </p>
        <ul>
          <li>
            <strong>analysing the goal</strong>
          </li>
          <li>
            <strong>gathering more detailed requirements</strong>
          </li>
          <li>
            <strong>building the system</strong>
          </li>
          <li>
            <strong>testing and training</strong>
          </li>
          <li>
            <strong>
              successful implementation of the solution in your production
              environment and business organisation.
            </strong>
          </li>
        </ul>
      </>
    ),
    additional: (
      <>
        <p>
          Not only do our highly experienced Consultants have ERP expertise, we
          also focus on facilitating Change and Process Management
          transformation. We help our clients to ‘own’ their new system and aid
          a progressive mastery of it.
        </p>
        <p>
          The company was founded by senior ERP consultants with decades of
          experience, who apply their long experience and knowledge to provide
          flexible, committed and reliable services with a single focus:
          delivering our clients’ results.
        </p>
      </>
    ),
  },
  slogan: ["We work", "with clients from", "various industries"],
  clients: {
    autoplayInterval: 8,
    logos: [
      <StaticImage
        {...logoProps}
        src="client-logos/airbus.png"
        alt="airbus"
        key="airbus"
      />,
      <StaticImage
        {...logoProps}
        src="client-logos/airconic.png"
        alt="airconic"
        key="airconic"
      />,
      <StaticImage
        {...logoProps}
        src="client-logos/bd.png"
        alt="bd"
        key="bd"
      />,
      <StaticImage
        {...logoProps}
        src="client-logos/dhl.png"
        alt="dhl"
        key="dhl"
      />,
      <StaticImage
        {...logoProps}
        src="client-logos/ecco.png"
        alt="ecco"
        key="ecco"
      />,
      <StaticImage
        {...logoProps}
        src="client-logos/eu-commission.png"
        alt="eu-commission"
        key="eu-commission"
      />,
      <StaticImage
        {...logoProps}
        src="client-logos/hema.png"
        alt="hema"
        key="hema"
      />,
      <StaticImage
        {...logoProps}
        src="client-logos/merck.png"
        alt="merck"
        key="merck"
      />,
      <StaticImage
        {...logoProps}
        src="client-logos/panalpina.png"
        alt="panalpina"
        key="panalpina"
      />,
      <StaticImage
        {...logoProps}
        src="client-logos/tenneco.png"
        alt="tenneco"
        key="tenneco"
      />,
      <StaticImage
        {...logoProps}
        src="client-logos/thales.png"
        alt="thales"
        key="thales"
      />,
    ],
  },
  testimonials: {
    autoplayInterval: 10,
    items: [
      {
        author: {
          name: "ANGELA FANKHAENEL",
          title: [
            "HEAD OF PROCESS STANDARTIZATION CDZ,",
            "DHL GLOBAL FORWARDING MANAGEMENT",
          ],
        },
        text:
          "Assen is very good in developing various solution approaches. He is absolutely reliable and delivers results in time. He is able to work under extreme time pressure. He is very good in managing different cultures and languages; this is very helpful in such global projects!",
        fontSize: 13,
      },
      {
        author: {
          name: "ANTHONY MILLER",
          title: ["HEAD OF SERVICE, ACS, UNITED KINGDOM"],
        },
        text:
          "Yury's good system knowledge, and ability to analyse and resolve problems has led to the safe delivery of projects, on time and within budget. Yury has always proved reliable and competent and I would not hesitate to re-employ him in the future.",
        fontSize: 13,
      },
      {
        author: {
          name: "STEVE KIRK",
          title: ["GTS CPO & APPLICATION OWNER, DUPONT"],
        },
        text:
          "Assen is very good at listening and explaining how GTS works and a very good team player, delivering on time and enabling the project to be a success.",
        fontSize: 13,
      },
      {
        author: {
          name: "PEDRO AZEVEDO",
          title: ["PROJECT MANAGER, ECCO"],
        },
        text:
          "Assen and his team were strongly committed, efficient and reliable in executing the required tasks; they have supported us diligently thoroughly and after the implementations.",
        fontSize: 13,
      },
      {
        author: {
          name: "Carine Brossard",
          title: ["TCS Customs Head, THALES"],
        },
        text:
          "Assen managed to give us confidence and a new breath to the group. The work you did was beneficial and allow us soon to go live. You were the right man for this project.",
        fontSize: 13,
      },
      {
        author: {
          name: "Rebecca Mann",
          title: ["Head of IT DHL Global Forwarding Canada"],
        },
        text:
          "Thanks for all your help with this project – Assen’s ability to cut through the layers and identify the critical issues allowed us to focus on what we had to do and made the project much more manageable for CA.",
        fontSize: 13,
      },
      {
        author: {
          name: "Catalin FLOREAN",
          title: [
            "Information Officer - Joint Technical Secretariat",
            "NTERREG IVB NEW, EU Commission",
          ],
        },
        text:
          "It was a pleasure working with Assen; I’m really looking forward for other common projects!",
        fontSize: 13,
      },
      {
        author: {
          name: "Sjaak van den Berg",
          title: [
            "Project Kite, Workstream Transportation & Customs",
            "Koopman International",
          ],
        },
        text:
          "Wisdom treasury supported me in implementing an elaborate SAP GTS system integrated with both SAP Business Suite on HANA and SAP Transportation Management at a large trading company.  The consultants of WT distinguish themselves by having not only great techno-functional knowledge, but they actually understand the business we are in and how customs authorities operate. Their understanding of international trade, the functionality of GTS, integration with other systems and their network in the GTS community was paramount for making our implementation succeed. With their knowledge, “can do” mentality and high flexibility they managed to gain the respect from management and users and proved a trustworthy partner. I would highly recommend them to any company interested in implementing SAP GTS.",
        fontSize: 11,
      },
      {
        author: {
          name: "Normunds Egmunds",
          title: [
            "Ministry of Finance EU Funds monitoring department",
            "MIS Expert, Latvia, EU Commission",
          ],
        },
        text:
          "It was my pleasure to meet and share the experiences and views with Assen. In my opinion you were real asset for the EC services.",
        fontSize: 13,
      },
    ],
  },
  getInTouchSlogan: [
    "Let’s discuss how our experience",
    "can make your business smarter",
  ],
}
