import React from "react"

export interface QuoteProps {
  className?: string
}
export const Quote = ({ className }: QuoteProps) => (
  <svg className={className} viewBox="0 0 171.233 140.208">
    <path d="M0 140.208C13.217 91.938 37.925 29.881 54.013 3.447L86.189 0c-12.642 33.9-27.579 93.089-33.9 134.46zm85.619 0c12.636-48.27 37.919-110.327 53.437-136.761L171.232 0c-12.642 33.9-27.585 93.089-33.9 134.46z" />
  </svg>
)
