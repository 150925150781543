import React from "react"

export const SapButton = () => (
  <svg viewBox="0 0 1014.1 669.8">
    <path d="M222.5 106.3c-10.6-8.6-21.2-15-31.8-19.1-10.6-4.1-20.9-6.2-30.8-6.2-12.6 0-22.8 3-30.8 8.9s-11.9 13.7-11.9 23.3c0 6.6 2 12.1 6 16.4 4 4.3 9.2 8 15.6 11.2 6.5 3.1 13.7 5.9 21.8 8.2 8.1 2.3 16.1 4.8 24.1 7.5 31.8 10.6 55 24.8 69.8 42.5 14.7 17.7 22.1 40.8 22.1 69.3 0 19.2-3.2 36.6-9.7 52.1-6.5 15.6-15.9 28.9-28.3 40-12.4 11.1-27.6 19.7-45.7 25.8-18 6.1-38.5 9.2-61.3 9.2-47.3 0-91.2-14.1-131.6-42.2l41.7-78.5c14.6 12.9 29 22.5 43.2 28.8 14.2 6.3 28.3 9.4 42.2 9.4 15.9 0 27.7-3.6 35.5-10.9 7.8-7.3 11.7-15.6 11.7-24.8 0-5.6-1-10.5-3-14.7-2-4.1-5.3-7.9-9.9-11.4-4.6-3.5-10.7-6.7-18.1-9.7-7.4-3-16.5-6.3-27.1-9.9-12.6-4-24.9-8.4-37-13.2-12.1-4.8-22.8-11.2-32.3-19.1s-17.1-18-22.8-30c-5.8-12.1-8.7-27.4-8.7-45.9s3.1-35.3 9.2-50.4c6.1-15.1 14.7-28 25.8-38.7C61.5 23.3 75.1 14.9 91.1 8.9 107.2 3 125.1 0 145 0c18.5 0 37.9 2.6 58.1 7.7 20.2 5.1 39.6 12.7 58.1 22.6l-38.7 76zM560.2 319.8h-139l-22.3 65.1H295L437.5 10.4h106.3l142.5 374.4H582.5l-22.3-65zm-25.9-74l-43.7-124.6-43.7 124.6h87.4zM826.3 384.9H729V10.4h154.9c42 0 74.2 10.9 96.6 32.8s33.5 52.6 33.5 92.4c0 39.7-11.2 70.5-33.5 92.4s-54.5 32.8-96.6 32.8h-57.6v124.1zm0-202.7h32.3c35.8 0 53.6-15.6 53.6-46.7s-17.9-46.7-53.6-46.7h-32.3v93.4z" />
    <g>
      <path d="M45.1 669.8c-2.3 0-4.6-.9-6.4-2.6-3.5-3.5-3.5-9.2 0-12.8l94-94c3.5-3.5 9.1-3.5 12.7-.1l96.7 94c3.6 3.5 3.7 9.2.2 12.8-3.5 3.6-9.2 3.7-12.8.2l-90.3-87.8-87.7 87.7c-1.8 1.7-4.1 2.6-6.4 2.6z" />
    </g>
  </svg>
)
