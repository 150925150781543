import { useCallback, useEffect, useState } from "react"

export const useSlider = (length: number, interval: number, hover: boolean) => {
  const [index, setIndex] = useState(0)

  const move = useCallback(
    (index: number, delta: number) => {
      const newIndex = (index + delta) % length
      return newIndex < 0 ? length + newIndex : newIndex
    },
    [length],
  )
  const inc = useCallback(() => setIndex(i => move(i, 1)), [move])
  const dec = useCallback(() => setIndex(i => move(i, -1)), [move])

  useEffect(() => {
    if (!hover && interval) {
      const id = setInterval(inc, interval * 1000)
      return () => clearInterval(id)
    }
  }, [interval, hover, inc])

  return { index, hover, setIndex, inc, dec, move }
}
