import React, { ReactNode } from "react"

import s from "./testimonial-layout.module.scss"

export interface TestimonialLayoutProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children: ReactNode
}
export const TestimonialLayout = ({
  children,
  ...rest
}: TestimonialLayoutProps) => (
  <div className={s.testimonialLayout} {...rest}>
    <div>{children}</div>
  </div>
)
