import React, { ReactNode } from "react"

import { useHover } from "../../../hooks/use-hover"
import { useSlider } from "../../../hooks/use-slider"
import s from "./clients-slider.module.scss"

export interface ClientsSliderProps {
  logos: ReactNode[]
  autoplayInterval: number
}
export const ClientsSlider = ({
  logos,
  autoplayInterval,
}: ClientsSliderProps) => {
  const [hover, hoverHandlers] = useHover()
  const { index, inc, dec, move } = useSlider(
    logos.length,
    autoplayInterval,
    hover,
  )

  return (
    <div id={s.wrapper} {...hoverHandlers}>
      <button id={s.prevArrow} onClick={dec}>
        <svg viewBox="0 0 53.885 101.937">
          <path
            d="M50.885 3L3 51.075l47.885 47.862"
            fill="none"
            stroke="#09c"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="6"
          />
        </svg>
      </button>
      <button id={s.nextArrow} onClick={inc}>
        <svg viewBox="0 0 53.885 101.937">
          <path
            d="M3 98.937l47.885-48.075L3 3"
            fill="none"
            stroke="#09c"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="6"
          />
        </svg>
      </button>
      <div id={s.canvas}>
        {[-2, -1, 0, 1, 2].map(i => {
          const idx = move(index, i)
          return (
            <div key={idx} id={s[`logo_${i + 2}`]}>
              {logos[idx]}
            </div>
          )
        })}
      </div>
    </div>
  )
}
