import React, { useMemo } from "react"

import { MultilineText } from "../../../multiline-text"
import { Quote } from "./quote"
import s from "./testimonial-content.module.scss"

export interface TestimonialContentProps {
  text: string
  author: {
    name: string
    title: string[]
  }
  fontSize: number
}
export const TestimonialContent = ({
  text,
  author: { name, title },
  fontSize,
}: TestimonialContentProps) => {
  const author = useMemo(
    () => (title.length ? [`${name}, ${title[0]}`, ...title.slice(1)] : [name]),
    [name, title],
  )
  return (
    <div
      className={s.wrapper}
      style={{
        lineHeight: `${(fontSize * 2) / 13}rem`,
      }}
    >
      <div className={s.content}>
        <p className={s.text} style={{ fontSize: `${fontSize / 10}rem` }}>
          <Quote className={s.openQuote} />
          {text}
          <Quote className={s.closeQuote} />
        </p>
      </div>
      <p
        className={`page-color ${s.author}`}
        style={{ fontSize: `${fontSize / 13}rem` }}
      >
        <MultilineText text={author} />
      </p>
    </div>
  )
}
