// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable simple-import-sort/imports */
import React from "react"

import { Layout } from "../components/layout"
import { About } from "../components/about"
import { aboutContent } from "../content/about"

const { pageTitle, ...aboutProps } = aboutContent

export default function AboutPage() {
  return (
    <Layout pageTitle={pageTitle} color="blue">
      <About {...aboutProps} />
    </Layout>
  )
}
