import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useRef, useState } from "react"

import { GetInTouch } from "../get-in-touch"
import { ImageBackground } from "../image-background"
import { Link } from "../link"
import { Logo } from "../logo"
import { MultilineText } from "../multiline-text"
import s from "./about.module.scss"
import { ClientsSlider } from "./clients-slider"
import { AboutDataProps } from "./dataProps"
import { CourseButton } from "./img/course-button"
import { SapButton } from "./img/sap-button"
import { Testimonials } from "./testimonials"

export interface AboutProps extends AboutDataProps {
  editor?: boolean
}

function toRem(px: number) {
  const { fontSize } = getComputedStyle(
    document.getElementsByTagName("html")[0],
  )
  // +1 correction because of: .descriptionContent p {margin: 0.5rem 0}
  return px / parseFloat(fontSize) + 1
}

const COMPANY_DESCRIPTION_ID = "company-description"

export const About = ({
  editor,
  wtDescription: { main: mainDescription, additional: additionalDescription },
  slogan,
  clients: { autoplayInterval: clientsAutoplayInterval, logos: clientLogos },
  testimonials: {
    autoplayInterval: testimonialsAutoplayInterval,
    activeIdx: activeTestimonialIdx,
    items: testimonials,
  },
  getInTouchSlogan,
}: AboutProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [showMore, setShowMore] = useState(editor || false)
  const [, repaint] = useState(0)

  useEffect(() => {
    let additionalDescriptionHeight: number
    function handleResize() {
      if (
        ref.current &&
        additionalDescriptionHeight !== ref.current.clientHeight
      ) {
        additionalDescriptionHeight = ref.current.clientHeight
        repaint(a => a + 1)
      }
    }
    if (window && showMore) {
      window.addEventListener("resize", handleResize)
      return () => window.removeEventListener("resize", handleResize)
    }
  }, [showMore])

  return (
    <>
      <ImageBackground outerClassName={s.mainImage}>
        <StaticImage
          placeholder="none"
          layout="fluid"
          maxWidth={5120}
          src="img/about-main.png"
          alt="Make Your Business Smart"
          transformOptions={{}}
          blurredOptions={{}}
        />
        <Link id={s.mainImageLink} refId={COMPANY_DESCRIPTION_ID} />
      </ImageBackground>

      <div id={COMPANY_DESCRIPTION_ID} className={s.companyDescription}>
        <div id={s.mainDescription}>
          <Logo />
          <div id={s.mainDescriptionContent} className={s.descriptionContent}>
            {mainDescription}
          </div>
        </div>
        {additionalDescription && (
          <div id={s.additionalDescription}>
            <div
              id={s.additionalDescriptionWrapper}
              style={
                editor
                  ? {}
                  : {
                      height:
                        showMore && ref.current
                          ? `${toRem(ref.current.clientHeight)}rem`
                          : 0,
                    }
              }
            >
              <div
                ref={ref}
                id={s.additionalDescriptionContent}
                className={s.descriptionContent}
              >
                {additionalDescription}
              </div>
            </div>
            <button
              className="button with-border page-color"
              onClick={editor ? undefined : () => setShowMore(s => !s)}
            >
              READ {showMore ? "LESS" : "MORE"}
            </button>
          </div>
        )}
      </div>
      <div id={s.mainMenu}>
        <div>
          <svg version="1.1" viewBox="0 0 80 60" className={s.mainMenu}>
            <filter id="shadow-0.5">
              <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
            </filter>
            <polygon
              className="shadow"
              points="80,20 56,26 7,38.5 80,57"
              filter="url(#shadow-0.5)"
            ></polygon>
            <polygon
              className="gray"
              points="80,20 56,26 7,38.5 80,57"
            ></polygon>
            <polygon
              className="shadow"
              points="0,1 56,26 0,51"
              filter="url(#shadow-0.5)"
            ></polygon>
            <polygon className="page-color" points="0,1 56,26 0,51"></polygon>
          </svg>
          <div id={s.mainMenuLinks}>
            <div>
              <Link pageID="sap" id={s.sapLink}>
                <SapButton />
              </Link>
              <Link pageID="course" id={s.courseLink}>
                <CourseButton />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div id={s.dotBackground}>
        <div id={s.slogan} className="page-color">
          <MultilineText text={slogan} />
        </div>
        <ClientsSlider
          logos={clientLogos}
          autoplayInterval={clientsAutoplayInterval}
        />
      </div>
      <div id={s.testimonials}>
        <div>
          <Testimonials
            autoplayInterval={testimonialsAutoplayInterval}
            activeIdx={activeTestimonialIdx}
            items={testimonials}
            // editor={editor}
          />
        </div>
      </div>
      <GetInTouch slogan={getInTouchSlogan} />
    </>
  )
}
