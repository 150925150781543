import React from "react"

import { useHover } from "../../../hooks/use-hover"
import { Testimonial } from "../dataProps"
import { TestimonialContent } from "./testimonial-content"
import { TestimonialLayout } from "./testimonial-layout"
import { TestimonialManager } from "./testimonial-manager"

export interface TestimonialsProps {
  autoplayInterval: number
  activeIdx?: number
  items: Testimonial[]
  editor?: boolean
}
export const Testimonials = ({
  autoplayInterval,
  activeIdx,
  items,
  editor,
}: TestimonialsProps) => {
  const [hover, hoverHandlers] = useHover()

  if (editor) {
    return (
      <>
        {items.map((t, i) => (
          <TestimonialLayout key={i}>
            <TestimonialContent {...t} />
          </TestimonialLayout>
        ))}
      </>
    )
  }

  return (
    <TestimonialLayout {...hoverHandlers}>
      <TestimonialManager
        autoplayInterval={autoplayInterval}
        activeIdx={activeIdx}
        items={items}
        hover={hover}
      />
    </TestimonialLayout>
  )
}
