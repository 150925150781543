import React from "react"
import { CSSTransition } from "react-transition-group"

import { useSlider } from "../../../../hooks/use-slider"
import { Testimonial } from "../../dataProps"
import { TestimonialContent } from "../testimonial-content"
import s from "./testimonial-manager.module.scss"
import transitionsCss from "./transitions.module.css"

export interface TestimonialsProps {
  autoplayInterval: number
  activeIdx?: number
  items: Testimonial[]
  hover: boolean
}
export const TestimonialManager = ({
  autoplayInterval,
  activeIdx,
  items,
  hover,
}: TestimonialsProps) => {
  const { index, setIndex } = useSlider(items.length, autoplayInterval, hover)
  const activeIndex = activeIdx ?? index
  return (
    <>
      {items.map((t, i) => (
        <CSSTransition
          key={i}
          in={i === activeIndex}
          timeout={1500}
          classNames={{ ...transitionsCss }}
          unmountOnExit
        >
          <TestimonialContent {...items[i]} />
        </CSSTransition>
      ))}
      <ul id={s.dots}>
        {Array.from(Array(Math.min(9, items.length)).keys()).map((_, i) => (
          <li key={i}>
            <button
              className={activeIndex !== i ? s.inactive : undefined}
              onClick={() => setIndex(i)}
            >
              <div>{i}</div>
            </button>
          </li>
        ))}
      </ul>
    </>
  )
}
