import { useCallback, useState } from "react"

export const useHover = (): [
  hover: boolean,
  hoverMethods: {
    onMouseEnter: () => void
    onMouseLeave: () => void
    onMouseOver: () => void
  },
] => {
  const [hover, setHover] = useState(false)
  const hoverOn = useCallback(() => setHover(true), [])
  const hoverOff = useCallback(() => setHover(false), [])
  return [
    hover,
    { onMouseEnter: hoverOn, onMouseLeave: hoverOff, onMouseOver: hoverOn },
  ]
}
